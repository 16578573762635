import React, {useState} from 'react';
import { useAuth ,logout} from '../auth'
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Card, CardMedia, CardContent } from '@mui/material';
import evvpLogo from '../styles/EVVAP-WHITE.png';
import '../styles/main.css';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


const drawerWidth = "20%";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%', // Adjust width as needed
  height: '70%',
  overflow: 'hidden', // Hide overflow to ensure image fits within modal
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
};

const videoStyle = {
  maxWidth: '100%', // Ensure image scales to fit the modal width
  maxHeight: '100%', // Ensure image scales to fit the modal height
  width: '100%',
  height: '100%',
};


const NavBar = ({showCalendar, setShowVideos, albumID, state, setState, setShowSettings, loading, setLoading}) => {

    const [logged] = useAuth();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false); 
    const archive = () => setShowVideos(true); 
    const settings = () => setShowSettings(true);
    const [videoURL, setVideoURL] = useState(''); 
    const [disabled, setDisabled] = useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const { window } = Window;
    const handleDrawerClose = () => {
      setIsClosing(true);
      setMobileOpen(false);
    };
  
    const handleDrawerTransitionEnd = () => {
      setIsClosing(false);
    };
  
    const handleDrawerToggle = () => {
      if (!isClosing) {
        setMobileOpen(!mobileOpen);
      }
    };

    const handleDateChange = (item) => {
      // Update the state with the new selection
      setState([item.selection]);
    
      // Check if both startDate and endDate are set, then enable the button
      if (item.selection?.startDate && item.selection?.endDate) {
        setDisabled(false);
      }
    };

    const generate_timelapse = async () =>  {

      // Prepare data to send in the request
      const formData = new FormData();
      formData.append('begin_date', state[0]?.startDate);  // Replace with actual begin date
      formData.append('end_date', state[0]?.endDate);    // Replace with actual end date


      try {
        setLoading({ ...loading, ["circular"]: true })
        const response = await fetch(`/photos/generate_timelapse/` + albumID, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok){
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setLoading({ ...loading, ["circular"]: false })
        setVideoURL('photos/' + result.generated_video_path)
        setOpen(true);
        // You can handle the result here, e.g., display it to the user
        // For example, you might want to show the video path or provide a download link

      } catch (error) {
        console.error('Error generating timelapse:', error);
      }
    };

    const drawer = (      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        component="img"
        sx={{
          height: 'auto',
          width: 233,
          margin: '10px',
        }}
        src={evvpLogo}
      />
      {showCalendar && (
        <>
          <Divider sx={{ width: '80%' }} />
          <div className='calendarWrapper' style={{ width: '100%', marginTop: '10px' }}>
            <DateRange
              style={{ backgroundColor: "rgb(0,0,0,0.9)" }}
              editableDateInputs={true}
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              ranges={state}
            />
          </div>
          <Button
            sx={{
              width: '80%',
              backgroundColor: 'red',
              color: 'white',
              margin: '10px',
            }}
            disabled={disabled}
            onClick={() => { generate_timelapse() }}
          >
            Generate Timelapse
          </Button>
          <Divider sx={{ width: '80%' }} />
        </>
      )}
      <Button
        sx={{
          width: '80%',
          backgroundColor: 'red',
          color: 'white',
          marginTop: '30px',
        }}
        onClick={() => { archive() }}
      >
        Generated Timelapses
      </Button>
      <Divider sx={{ width: '80%' }} />
      <Button
        sx={{
          width: '80%',
          backgroundColor: 'red',
          color: 'white',
          marginTop: '10px',
        }}
        onClick={() => { settings() }}
      >
        Settings
      </Button>
      <Divider sx={{ width: '80%' }} />
      <Button
        sx={{
          width: '50%',
          color: 'white',
          backgroundColor: 'gray',
          margin: '10px',
        }}
        onClick={() => { logout() }}
      >
        Log Out
      </Button>
    </Box>)

const mobileDrawer = (     
   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box
    component="img"
    sx={{
      height: 'auto',
      width: '50%',
      margin: '10px',
    }}
    src={evvpLogo}
  />
  {showCalendar && (
    <>
      <Divider sx={{ width: '50%' }} />
      <div className='calendarWrapper' style={{ width: '100%', marginTop: '10px' }}>
        <DateRange
          style={{ backgroundColor: "rgb(0,0,0,0.9)" }}
          editableDateInputs={true}
          onChange={handleDateChange}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          ranges={state}
        />
      </div>
      <Button
        sx={{
          width: '50%',
          backgroundColor: 'red',
          color: 'white',

        }}
        disabled={disabled}
        onClick={() => { generate_timelapse() }}
      >
        Generate Timelapse
      </Button>
      <Divider sx={{ width: '80%' }} />
    </>
  )}
  <Button
    sx={{
      width: '50%',
      backgroundColor: 'red',
      color: 'white',
      marginTop: '30px',
    }}
    onClick={() => { archive() }}
  >
    Generated Timelapses
  </Button>
  <Divider sx={{ width: '50%' }} />
  <Button
    sx={{
      width: '50%',
      backgroundColor: 'red',
      color: 'white',
      marginTop: '10px',
    }}
    onClick={() => { settings() }}
  >
    Settings
  </Button>
  <Divider sx={{ width: '50%' }} />
  <Button
    sx={{
      width: '50%',
      color: 'white',
      backgroundColor: 'gray',
      margin: '10px',
    }}
    onClick={() => { logout() }}
  >
    Log Out
  </Button>
</Box>)

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Fade in={open} timeout={500}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Card
              style={videoStyle}
              >
              <CardMedia
                component="video"
                controls
                src={videoURL}
                alt="Video description"
              />
              <CardContent>
              </CardContent>
            </Card>
              </div>
            </Fade>
          </Box>
        </Modal>
        {logged && (
          <Box sx={{ display: 'flex' }} style={{ backgroundColor: "rgb(0,0,0,0.7)" }}>   
              <AppBar
              position="fixed"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)`},
                ml: { sm: `${drawerWidth}px` },
                color:"inherit",
              }}
              style={{ backgroundColor: "rgb(0,0,0,0.5)" }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          anchor='top'
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', backgroundColor: "rgb(0,0,0,0.5)" },
          }}
        >
          {mobileDrawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "rgb(0,0,0,0.5)"  },
          }}
          open
        >
          {drawer}
        </Drawer>
        </Box>
        )}
      </>
    );
  };
export default NavBar;
